import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Button } from 'mw-style-react';
import './ContentC.scss';
import Image from '../../../../components/Image/Image';
import Utils from '../../../../utils/utils';
import Link from '../../../../i18n/Link';
import iconArrow from '../../../../images/arrow.svg';
import Benefits from '../../../../components/Benefits/Benefits';

class ContentC extends PureComponent {
  render() {
    const { content, intl } = this.props;
    return (
      <div className="main__contentC">
        <div className="main__contentC__container">
          {content.map((item, index) => {
            if (item.key === 'events' || item.key === 'knowledge') {
              return <div key={index}/>;
            }
            return (
              <div key={index} className="main__contentC__item">
                <div className="main__contentC__item__image">
                  <div className="main__contentC__item__image__wrap">
                    <Image file={item.image}/>
                  </div>
                </div>
                <h2
                  className="main__contentC__title"
                  dangerouslySetInnerHTML={{
                    __html: Utils.bbCodeToHtml(intl.formatMessage({id: item.title}))
                  }}
                />
                {item.subTitle &&
                  <span
                    className="main__contentC__subTitle"
                    dangerouslySetInnerHTML={{
                      __html: Utils.bbCodeToHtml(intl.formatMessage({id: item.subTitle}))
                    }}
                  />
                }
                <Link to={item.btn.link} className="main__contentC__item__link">
                  <p>
                    {intl.formatMessage({id: item.btn.title})}
                  </p>
                  <img src={iconArrow} alt="More"/>
                </Link>
              </div>
            );
          })}
          <div className="main__contentC__item last">
            <div className="main__contentC__item__image">
              <div className="main__contentC__item__image__wrap">
                <Image file={'corezoid.png'}/>
              </div>
            </div>
            <h2 className="main__contentC__title">
              {intl.formatMessage({id: 'postDemoTitle'})}
            </h2>
            <Link
              to={process.env.GATSBY_HOST}
              className="main__contentC__btn__container"
            >
              <Button
                className="button__default"
                label={intl.formatMessage({id: 'startFreeDemo'})}
              />
            </Link>
          </div>
        </div>
        <div className="main__contentC__benefits">
          <Benefits />
        </div>
        <div className="main__contentB__demo__wrap">
          <div className="main__contentB__demo">
            <Image className="main__contentB__demo__image" file={'corezoid.png'} />
            <h2 className="main__contentB__demo__text">{intl.formatMessage({id: 'postDemoTitle'})}</h2>
            <Link
              to={''}
              className="main__contentB__demo__btn__container"
              // event={item.btn.event}
            >
              <Button
                className="button__default main__contentB__demo__btn"
                label={intl.formatMessage({id: 'startFreeDemo'})}
              />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

ContentC.propTypes = {
  content: PropTypes.array,
  intl: PropTypes.object
};

export default injectIntl(ContentC);

