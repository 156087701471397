import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cn from 'classnames';
import {
  Button
} from 'mw-style-react';
// import Image from '../../../../components/Image/Image';
import backgroundVideo from '../../../../videos/Carousel.mp4';
import backgroundAudio from '../../../../videos/Carousel-audio.mp3';
import './Header.scss';
import { Link } from '../../../../i18n';
import MuteButton from '../MuteButton';

class Header extends PureComponent {
  constructor(props) {
    super(props);
    this.audioRef = React.createRef();

    this.state = {
      muted: true
    };
  }

  handleMuteChange() {
    const audio = this.audioRef.current;
    audio.muted = !audio.muted;

    if (audio.paused) {
      audio.play();
    }

    this.setState({
      muted: audio.muted
    });
  }

  render() {
    const { intl, list, content } = this.props;
    const { muted } = this.state;
    let classTitle = 'main__header__title';
    let buttonWidth = '26%';
    const pFontSize = '15px';
    if (intl.locale === 'en') {
      classTitle = 'main__header__bigTitle';
      buttonWidth = '23%';
    }

    return (
      <div className="main__header">
        <div className="main__header__container">
          <div className="col-12 main__header__titleContainer">
            <div className={classTitle}>
              <h1>{intl.formatMessage({id: content.title})}</h1>
            </div>
            <div className="main__header__subTitle">
              <h2>{intl.formatMessage({id: content.subTitle})}</h2>
            </div>
          </div>
          <div className="col-12 main__header__img">
            {list.map((item, index) => {
              const key = item.key;
              if (key === 'events' || key === 'knowledge') {
                return <div key={index}/>;
              }
              return (
                <Link key={index} to={item.btn.link}>
                  <div className={cn('main__header__img__link', key)}/>
                </Link>
              );
            })}
            <div className="video__wrapper">
              <video className="video__content" autoPlay loop muted>
                <source src={backgroundVideo} type="video/mp4"/>
              </video>
              <audio loop muted autoPlay ref={this.audioRef}>
                <source src={backgroundAudio} type="audio/mpeg" />
              </audio>
              <div className="mute__wrapper">
                <MuteButton handleClick={() => this.handleMuteChange()} muted={muted} />
              </div>
            </div>
          </div>
        </div>
        <div className="main__header__buttons__container">
          <div className="col-12 main__header__buttons">
            <Link
              to={content.buttonOnline.link}
              className="main__header__btnContainer"
              style={{width: buttonWidth}}
              event={'free_cloud_trial'}
              id="tycoon_free_cloud"
            >
              <Button
                className={cn('button__default main__header__btn')}
                label={intl.formatMessage({id: content.buttonOnline.text})}
              />
              <p style={{fontSize: pFontSize}}>{intl.formatMessage({id: 'registrationSubText'})}</p>
            </Link>
            <Link
              to={content.buttonDownload.link}
              className="main__header__btnContainer"
              style={{width: buttonWidth}}
              event={'free_download'}
              id="tycoon_free_download"
            >
              <Button
                className={cn('button__secondary main__header__btn')}
                label={intl.formatMessage({id: content.buttonDownload.text})}
              />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  intl: PropTypes.object,
  content: PropTypes.object,
  list: PropTypes.array,
};

export default injectIntl(Header);

