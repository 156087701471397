import React from 'react';
import PropTypes from 'prop-types';

import './MuteButton.scss';

import SoundOff from './components/SoundOff';
import SoundOn from './components/SoundOn';

const MuteButton = ({ muted, handleClick }) => (
  <button
    className="button__mute"
    onClick={handleClick}
    tabIndex={0}
  >
    {muted ? (
      <SoundOff />
    ) : (
      <SoundOn />
    )}
  </button>
);

MuteButton.propTypes = {
  muted: PropTypes.bool,
  handleClick: PropTypes.func.isRequired
};

MuteButton.defaultProps = {
  muted: false,
};

export default MuteButton;
