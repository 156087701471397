import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import MainBlock from '../../../../components/MainBlock';
import './Content.scss';
import Demo from '../../../../components/Demo/Demo';

class Content extends PureComponent {
  render() {
    const { content } = this.props;
    return (
      <div className="main__content">
        {content.map((item, index) => (
          <MainBlock
            key={index}
            index={index}
            item={item}
            className={item.key}
          />
        ))}
        <Demo/>
      </div>
    );
  }
}

Content.propTypes = {
  content: PropTypes.array,
};

export default injectIntl(Content);

