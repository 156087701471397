import React from 'react';
import PropTypes from 'prop-types';
import Main from '../templates/Main';
import Layout from '../components/Layout';
import { withIntl } from '../i18n';

const host = process.env.GATSBY_HOST;

const main = {
  header: {
    title: 'mainTitle',
    subTitle: 'mainSubTitle',
    buttonOnline: {
      text: 'faqDemoBtnCreate',
      link: `${host}/auth2/single_account?redirect_uri=${host}/&enter=signup`,
    },
    buttonDownload: {
      text: 'mainBtnDownload',
      link: '/download'
    }
  },
  list: [
    {
      key: 'software',
      title: 'mainContentSoftwareTitle',
      subTitle: 'mainContentSoftwareSubTitle',
      image: 'software.png',
      btn: {
        title: 'mainContentSoftwareBtnTitle',
        link: '/software',
        event: 'jungle_learm_more'
      }
    },
    {
      key: 'manage-api',
      title: 'mainContentApiTitle',
      subTitle: 'mainContentApiSubTitle',
      image: 'api.png',
      btn: {
        title: 'mainContentApiBtnTitle',
        link: '/manage-api',
        event: 'API_learn_more'
      }
    },
    {
      key: 'code',
      title: 'mainContentCodeTitle',
      subTitle: 'mainContentCodeSubTitle',
      image: 'code.png',
      btn: {
        title: 'mainContentCodeBtnTitle',
        link: '/code',
        event: 'public_code_learn_more'
      }
    },
    {
      key: 'processes',
      title: 'mainContentProcessesTitle',
      subTitle: 'mainContentProcessesSubTitle',
      image: 'process.png',
      btn: {
        title: 'mainContentProcessesBtnTitle',
        link: '/processes',
        event: 'focus_on_the_process_learn_more'
      }
    },
    {
      key: 'iot',
      title: 'mainContentIotTitle',
      subTitle: 'mainContentIotSubTitle',
      image: 'iot.png',
      btn: {
        title: 'mainContentIotBtnTitle',
        link: '/iot',
        event: 'IoT_jungle_learn_more'
      }
    },
    {
      key: 'communication',
      title: 'mainContentCommunicationTitle',
      subTitle: 'mainContentCommunicationSubTitle',
      image: 'communication.png',
      btn: {
        title: 'mainContentCommunicationBtnTitle',
        link: '/communication',
        event: 'automate_communications_learn_more'
      }
    },
    {
      key: 'events',
      title: 'mainContentEventsTitle',
      subTitle: 'mainContentEventsSubTitle',
      image: 'ControlEvents.png',
      btn: {
        title: 'mainContentEventsBtnTitle',
        link: '/events',
        event: 'Events / Learn more'
      }
    },
    {
      key: 'knowledge',
      title: 'mainContentKnowledgeTitle',
      subTitle: 'mainContentKnowledgeSubTitle',
      image: 'deepmemo-logo.png',
      btn: {
        title: 'mainContentKnowledgeBtnTitle',
        link: '/knowledge',
        event: 'Knowledge / Learn more'
      }
    },
    {
      key: 'people',
      title: 'mainContentPeopleTitle',
      subTitle: 'mainContentPeopleSubTitle',
      image: 'people.png',
      btn: {
        title: 'mainContentPeopleBtnTitle',
        link: '/people',
        event: 'manage_leads_learn_more'
      }
    },
    {
      key: 'cloud',
      title: 'mainContentCloudTitle',
      subTitle: 'mainContentCloudSubTitle',
      image: 'cloud.png',
      btn: {
        title: 'mainContentCloudBtnTitle',
        link: '/cloud',
        event: 'serverless_computing_learn_more'
      }
    }
  ]
};

const IndexPage = (props) => (
  <Layout page={'main'} {...props}>
    <Main
      content={main}
      data={props.data}
      {...props}
    />
  </Layout>
);

IndexPage.propTypes = {
  data: PropTypes.object
};

export default withIntl(IndexPage);
