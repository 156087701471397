import React from 'react';

const SoundOn = () => (
  <svg width="32px" height="32px" viewBox="0 0 32 32">
    <g fill="#DDE0E5">
      <g transform="translate(4.000000, 6.666667)">
        <path
          d="M19.6190579,9.33300609 C19.6229071,10.9529106 18.9969899,12.5089437 17.8772504,13.6631036 C17.5810485,13.9585427 17.1063386,13.9587928 16.8098348,13.6636659 C16.513331,13.3685391 16.5042445,12.8867395 16.7894032,12.5803085 C18.5305064,10.7764198 18.5305064,7.89021114 16.7894032,6.08632238 C16.5042445,5.77989144 16.513331,5.29809179 16.8098348,5.00296497 C17.1063386,4.70783816 17.5810485,4.70808824 17.8772504,5.00352729 C18.9968413,6.15753481 19.6227465,7.7133157 19.6190579,9.33300609 L19.6190579,9.33300609 Z M19.3779736,1.80796697 C19.0700506,1.52240106 18.593173,1.54123483 18.308037,1.85022297 C18.022901,2.15921111 18.0354971,2.64349697 18.3362978,2.93678085 C20.1227321,4.56874519 21.1428319,6.89374197 21.1428319,9.3333928 C21.1428319,11.7730436 20.1227321,14.0980404 18.3362978,15.7300048 C18.0407355,16.0243022 18.0307756,16.5043312 18.3138689,16.8110006 C18.5969622,17.1176701 19.0694904,17.1387325 19.3779736,16.8584319 C21.4720239,14.9342038 22.6666667,12.2006762 22.6666667,9.3333928 C22.6666667,6.4661094 21.4720239,3.73258178 19.3779736,1.80835369 L19.3779736,1.80796697 Z M2.28571335,4.69245573 C1.02334872,4.69245573 0,5.73127832 0,7.01273091 L0,11.6532813 C0,12.9347339 1.02334872,13.9735564 2.28571335,13.9735564 L4.5714267,13.9735564 L4.5714267,4.69245573 L2.28571335,4.69245573 Z M11.3169477,0.270165929 L6.0952356,3.98090468 L6.0952356,14.6858809 L11.3169477,18.396233 C11.7839234,18.7193575 12.3881186,18.7555941 12.8893273,18.4905363 C13.3905361,18.2254785 13.7075285,17.7020837 13.7142801,17.1284346 L13.7142801,1.53757757 C13.7071821,0.964093449 13.3901059,0.440995415 12.8890049,0.176075432 C12.3879039,-0.0888445502 11.7839093,-0.0526943658 11.3169477,0.270165929 Z"
          id="Shape"
        />
      </g>
    </g>
  </svg>
);

export default SoundOn;
