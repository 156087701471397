import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Header from './components/Header';
import Clients from '../../components/Clients';
import Content from './components/Content';
import ContentB from './components/ContentB';
import ContentC from './components/ContentC';
import './Main.scss';

class Main extends PureComponent {
  constructor() {
    super();
    this.state = {
      ab: '',
    };
  }

  componentDidMount() {
    // проверка на изменение класса
    let ab = '';
    setInterval(() => {
      if (!this.ab) return;
      const AB = this.ab.className.slice(-1);
      if (ab !== AB) {
        ab = AB;
        this.setState({
          ab,
        });
      }
    }, 10);
  }

  renderContent(type) {
    const { content, intl } = this.props;
    switch (type) {
      case 'A':
        return (
          <Content
            content={content.list}
            intl={intl}
          />
        );
      case 'B':
        return (
          <ContentB
            content={content.list}
            intl={intl}
          />
        );
      case 'C':
        return (
          <ContentC
            content={content.list}
            intl={intl}
          />
        );
      default:
        return <div />;
    }
  }

  render() {
    const { content, data } = this.props;
    const { ab } = this.state;
    return (
      <section className="main">
        <div className="main__topHeader"/>
        <Header
          content={content.header}
          list={content.list}
          images={data}
        />
        <Clients/>
        <div className="mainAB__B" ref={el => (this.ab = el)}>
          {this.renderContent(ab)}
        </div>
      </section>
    );
  }
}

Main.propTypes = {
  data: PropTypes.object,
  content: PropTypes.object,
  intl: PropTypes.object,
};

export default Main;

