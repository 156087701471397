import React from 'react';

const SoundOff = () => (
  <svg width="32px" height="32px" viewBox="0 0 32 32">
    <g fill="#DDE0E5">
      <g transform="translate(4.000000, 6.666667)">
        <path
          d="M11.3169477,0.270165929 C11.7839093,-0.0526943658 12.3879039,-0.0888445502 12.8890049,0.176075432 C13.3901059,0.440995415 13.7071821,0.964093449 13.7142801,1.53757757 L13.7142801,1.53757757 L13.7142801,17.1284346 C13.7075285,17.7020837 13.3905361,18.2254785 12.8893273,18.4905363 C12.3881186,18.7555941 11.7839234,18.7193575 11.3169477,18.396233 L11.3169477,18.396233 L6.0952356,14.6858809 L6.0952356,3.98090468 Z M4.5714267,4.69245573 L4.5714267,13.9735564 L2.28571335,13.9735564 C1.02334872,13.9735564 0,12.9347339 0,11.6532813 L0,11.6532813 L0,7.01273091 C0,5.73127832 1.02334872,4.69245573 2.28571335,4.69245573 L2.28571335,4.69245573 L4.5714267,4.69245573 Z M17.3024786,5.55680333 L20,8.25216674 L22.6975214,5.55680333 C22.9954814,5.25884334 23.47857,5.25884334 23.77653,5.55680333 C24.07449,5.85476332 24.07449,6.33785191 23.77653,6.6358119 L21.0790086,9.33117532 L23.77653,12.0308548 C24.07449,12.3288148 24.07449,12.8119034 23.77653,13.1098633 C23.47857,13.4078233 22.9954814,13.4078233 22.6975214,13.1098633 L20,10.4101839 L17.3024786,13.1098633 C17.0045186,13.4078233 16.52143,13.4078233 16.22347,13.1098633 C15.92551,12.8119034 15.92551,12.3288148 16.22347,12.0308548 L18.9209914,9.33117532 L16.22347,6.6358119 C15.92551,6.33785191 15.92551,5.85476332 16.22347,5.55680333 C16.52143,5.25884334 17.0045186,5.25884334 17.3024786,5.55680333 Z"
          id="Combined-Shape"
        />
      </g>
    </g>
  </svg>
);

export default SoundOff;
