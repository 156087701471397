import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Button } from 'mw-style-react';
import cn from 'classnames';
import './ContentB.scss';
import Image from '../../../../components/Image/Image';
import Utils from '../../../../utils/utils';
import allSlides from '../../../../constants/slides';
import Link from '../../../../i18n/Link';
import Demo from '../../../../components/Demo/Demo';
// const host = process.env.GATSBY_HOST;

class ContentB extends PureComponent {
  constructor(props) {
    super(props);
    const renderSlides = allSlides[props.intl.locale];
    this.state = {
      renderSlides,
    };
  }

  render() {
    const { content, intl } = this.props;
    const { renderSlides } = this.state;
    const partToSlide = {};
    renderSlides.forEach(s => {
      switch (s.text) {
        case 'sliderSpaceBank':
          partToSlide.software = s;
          break;
        case 'sliderMambu':
          // partToSlide.cloud = s;
          const newS = JSON.parse(JSON.stringify(s));
          newS.text = 'sliderMambu2';
          partToSlide['manage-api'] = newS;
          break;
        case 'sliderPumb':
          partToSlide.processes = s;
          break;
        case 'sliderTas':
          partToSlide.people = s;
          break;
        case 'sliderMetro':
          partToSlide.communication = s;
          break;
        case 'sliderLanit':
          partToSlide.cloud = s;
          break;
        case 'sliderGlovo':
            partToSlide.iot = s;
            break;
        case 'sliderBlueFinance':
              partToSlide.code = s;
              break;
        default:
      }
    });
    return (
      <div className="main__contentB">
        {content.map((item, index) => {
          if (item.key === 'knowledge' || item.key === 'events') {
            return <div key={index}/>;
          }

          return (
            <div key={index} className={cn('main__contentB__item__wrap', {greeyBG: index % 2})}>
              <div key={index} className="main__contentB__item">
                <div className="main__contentB__item__top">
                  <div className="main__contentB__item__content">
                    <h2
                      className="main__contentB__title"
                      dangerouslySetInnerHTML={{
                        __html: Utils.bbCodeToHtml(intl.formatMessage({id: item.title}))
                      }}
                    />
                    <span
                      className="main__contentB__subTitle"
                      dangerouslySetInnerHTML={{
                        __html: Utils.bbCodeToHtml(intl.formatMessage({id: item.subTitle}))
                      }}
                    />
                    <Link
                      to={item.btn.link}
                      className="mainBlock__content__btn__container"
                      event={item.btn.event}
                    >
                      <Button
                        className={cn('button__default', 'mainBlock__content__btn')}
                        label={intl.formatMessage({id: item.btn.title})}
                      />
                      {item.btn.subText &&
                        <p>{intl.formatMessage({id: item.btn.subText})}</p>
                      }
                    </Link>
                  </div>
                  <div className="main__contentB__item__image">
                    <Image file={item.image}/>
                  </div>
                </div>
                <div>
                  {partToSlide[item.key] &&
                    <div className="main__contentB__slide">
                      <div>
                        {'"'}
                        <span
                          className="main__contentB__slide__text"
                          dangerouslySetInnerHTML={{
                            __html: Utils.bbCodeToHtml(intl.formatMessage({
                              id: partToSlide[item.key].text
                            }))
                          }}
                        />
                        {'"'}
                      </div>
                      <div className="main__contentB__slide__bottom">
                        <Image className="main__contentB__slide__image" file={partToSlide[item.key].img}/>
                        <div className="main__contentB__slide__bottom__name">
                          <Image className="main__contentB__slide__bottom__name__image" file={partToSlide[item.key].avatar}/>
                          <div>{intl.formatMessage({id: partToSlide[item.key].name})},</div>
                          <div>{intl.formatMessage({id: partToSlide[item.key].status})}</div>
                        </div>
                        <Link to={partToSlide[item.key].link} className="slider__slide__link">
                          <span>{intl.formatMessage({id: 'sliderButton'})}</span>
                        </Link>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          );
        })}
        <Demo />
      </div>
    );
  }
}

ContentB.propTypes = {
  content: PropTypes.array,
  intl: PropTypes.object
};

export default injectIntl(ContentB);

